@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

body {
  margin: 0;
  color: #344054;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --scrollbar-width: 6px;
}

::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e6edf2;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #8f9faa;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import './v2/view/components/ClientPagination/ClientPagination.css';

.rbc-calendar {
  font-family: 'Inter', sans-serif !important;
  height: 80vh !important;
}

.rbc-time-view {
  border: none !important;
}

.rbc-timeslot-group {
  font-size: 12px;
  border-bottom: 1px solid #eaecf0 !important /* gray-100*/;
}

.rbc-header .rbc-button-link {
  font-weight: 500 !important;
}

.rbc-today {
  background: none !important;
}
.rbc-header.rbc-today .rbc-custom-day-header-day {
  color: #1570ef !important;
}

.rbc-header.rbc-today .rbc-custom-day-header-date {
  color: #fff;
  border-radius: 50%;
  background-color: #1570ef !important;
}

.rbc-header {
  border: none !important;
  height: 60px !important;
}

.rbc-time-content {
  height: 100vh !important;
  border-top: 1px solid #eaecf0 !important;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #eaecf0 !important; /* gray-100*/
}

.rbc-time-header.rbc-overflowing {
  border-right: none !important;
}

.rbc-time-header-content {
  border-left: 1px solid #eaecf0 !important; /* gray-100*/
}

.rbc-time-header-gutter {
  min-width: 43.92px !important;
  width: 43.92px !important;
}

.rbc-allday-cell {
  display: none;
}

.rbc-current-time-indicator {
  z-index: 5 !important;
}

.rbc-event {
  /* padding: 0px !important; */
  padding: 0px !important; /* Padding required in figma */
  border-radius: 5px !important;
  border: none !important;
  background-color: transparent !important;
  z-index: 4 !important;
  position: relative;
  /* width: 90% !important; */
  /* height: 3.8% !important; */ /* Having auto height based on time*/
}

.rbc-events-container {
  margin: 0 !important;
}

.rbc-event-content {
  height: 90% !important;
  border-radius: 0 !important;
}

.rbc-event:focus {
  outline: none !important; /* having box shadow in custom meeting component*/
}

.rbc-day-slot .rbc-background-event:focus {
  outline: 1.5px solid #1570ef !important;
}

.rbc-background-event {
  padding: 0px !important;
  border: none !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  opacity: 1 !important;
  width: 100%;
  z-index: 2 !important;
  /* cursor: default !important;
  pointer-events: none !important; */
}

.rbc-event-label {
  display: none !important;
}

.rdp-root {
  --rdp-font-family: 'Inter', sans-serif;
}

.rdp-caption_label {
  font-family: 'Inter', sans-serif;
}

.save-button-container {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: sticky;
  bottom: 0;
}

.form-submit-button {
  width: 285px;
  height: 44px;
  background-color: #263e58;
  color: white;
  border-radius: 100px;
  border: none;
  cursor: pointer;
  margin: 30px auto;
  display: block;
  font-weight: 600;
}

#sentry-feedback {
  --inset: auto auto 0 0;
}
