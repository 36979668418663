.layout-container {
  max-height: 98vh; // todo find an optimize way to handle th eheight dynamically
  width: calc(
    100vw - var(--scrollbar-width)
  ); // 6px is scrollbar width from index.css
  // height: 100%;
  // padding: 36px;
  background-color: #fcfcfd;
  position: relative;
  // background-color: red;

  .main-content {
    margin: 10px;
    min-width: calc(15rem - 100%);
    padding-top: 50px;
    @media (min-width: 1024px) {
      margin-left: 15rem;
    }

    &.no-page-margin {
      margin-left: 0px;
    }
    &.no-top-padding {
      padding-top: 0;
    }
  }
}
