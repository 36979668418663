.case-note-header-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .session-container {
    .session-header {
      margin-bottom: 0.5rem;
    }
  }
}
