@use '../../../configs/styles/defaultStyle.scss' as default;

@mixin flex($justifyContent: space-between, $alignItems: center) {
  display: flex;
  justify-content: $justifyContent;
  align-items: $alignItems;
}

@mixin followUpBoxBorder($border-color: rgba(208, 213, 221, 1)) {
  border: 1px solid $border-color;
  border-radius: 8px;
}

@mixin editorContainer() {
  .editor-container {
    border-radius: 8px;
    padding: 17px 21px;
    margin-top: 11px;
    .editor-input-wrapper {
      min-height: 62px;
      padding: 0;
      overflow-y: auto;
      textarea::placeholder {
        padding-left: 0.1rem;
      }
      .mentions {
        font-size: 14px;
      }
    }
    @include followUpBoxBorder();
    .char-count-container {
      padding-right: 0.1rem;
    }
    margin-bottom: 0.3rem;
  }
}

@mixin highRiskInform() {
  .high-risk-inform {
    @include flex();
    padding: 1rem;
    margin: 0.4rem 0;
    margin-bottom: 6px;
    section {
      @include flex(flex-start);
      flex-direction: row;
      gap: 1rem;
    }
    @include followUpBoxBorder();
    .info-content {
      align-items: baseline;
      background-color: #fff4e7;
      padding: 0.45rem;
      border-radius: 4px;
    }
    .inform-intellect {
      display: flex;
      justify-content: flex-end;
    }
  }
}
@mixin customTooltip {
  .tooltip-wrapper {
    display: inline-block;
  }
  .custom-tooltip {
    background: #fefaec;
    border-radius: 7px;
    color: #434444;
    width: 60%;
    flex-basis: 1;
    z-index: 5;
    font-size: 13px;
    font-weight: 400;
    font-family: 'Poppins';
    padding: 1rem;
  }
}
@mixin flexColWithGap() {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.followupSessionCaseNotes {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  min-height: 100vh;
  padding: 16px 90px;
  font-size: 16px;
  @include customTooltip();
  @include editorContainer();
  input[type='checkbox'] {
    height: 1rem;
    width: 1rem;
    accent-color: default.$primary-color;
    outline: none;
    cursor: pointer;
  }
  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .pill-icon {
      @include flex();
      min-height: 32px;
      padding: 5px 16px;
      margin-right: 0.72rem;
      width: max-content;
      border-radius: 100px;
      cursor: pointer;
      gap: 0.51rem;
      font-family: Poppins;
      background-color: #fff4e7;
    }
    &.riskAssessment-container {
      @include followUpBoxBorder();
      .section-title {
        padding: 0.1rem 0;
        .pill-icon {
          margin-right: 0;
        }
      }
      padding: 1rem;
    }
  }
  .section-title,
  .header {
    @include flex();
  }
  .section-title {
    padding: 0.56rem 0 0 0;
    margin-top: 7px;
    .subTextLimit {
      color: #828282;
    }
  }
  .header {
    padding: 1rem 0;
  }
  .react-tooltip {
    padding: 11px 16px;
    border-radius: 8px;
    font-size: 12px;
    width: 315px;
    z-index: 5;
  }

  .save-button {
    margin-top: 0.5rem;
    @include flex(center, center);
  }

  @include highRiskInform();

  .redirect-btn {
    @include flex(center, center);
    gap: 0.1rem;
  }

  .suicidal-assessments-container {
    @include flexColWithGap();
    position: relative;
    top: 10px;
    .suicidal-assessments {
      @include flexColWithGap();
      gap: 0;
    }
  }
  .additionalSessions {
    .editor-input-wrapper {
      height: 37px;
      overflow-y: hidden;
      min-height: 0;
      padding: 0;
      textarea {
        height: 90px !important;
        overflow-y: auto !important;
      }
    }
  }
}

.video-conferencing-followup {
  @include editorContainer();
  @include customTooltip();
  gap: 0.4rem;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  min-height: 100vh;
  padding: 0.4rem;
  .presentingIssues {
    flex-wrap: wrap;
  }
  .section-title,
  .header {
    @include flex();
  }
  .section-title {
    padding: 0.56rem 0 0 0;
    margin-top: 7px;
    .subTextLimit {
      color: #828282;
    }
  }
  .header {
    padding: 1rem 0;
  }
  .react-tooltip {
    padding: 11px 16px;
    border-radius: 8px;
    font-size: 12px;
    width: 315px;
    z-index: 5;
  }
  .section {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .risk-assessments-field {
    .custom-radio-groups {
      flex-wrap: wrap;
      row-gap: 1rem;
      column-gap: 1rem;
    }
  }

  .saveButton {
    @include flex(center, center);
  }
  @include highRiskInform();
}

.in-session-followup {
  img[alt='close'] {
    cursor: pointer;
  }

  .additionalSessions {
    .editor-input-wrapper {
      height: 3rem;
      overflow-y: hidden;
      padding: 0;
      textarea {
        height: 90px !important;
        overflow-y: auto !important;
      }
    }
  }
  .video-conferencing-followup {
    position: relative;
    top: 50px;
    padding: 0 14px;
    .redirect-btn {
      @include flex(center, center);
      gap: 0.1rem;
    }
  }
}
